<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" [(nzCollapsed)]="isCollapsed" *ngIf="webOne" [ngClass]="!isCollapsed ? 'w-250' : ''">
        <div class="sidebar-top-items">
            <span class="trigger" nz-icon [nzType]="isCollapsed ? 'right' : 'left'"
                (click)="isCollapsed = !isCollapsed"></span>
            <div class="sidebar-logo" *ngIf="!isCollapsed">
                <a href="https://ng.ant.design/" target="_blank">
                    <img src="../../../../assets/images/logodark.png" alt="logo">
                </a>
            </div>
            <div class="sidebar-logo-collapse" *ngIf="isCollapsed">
                <a href="https://ng.ant.design/" target="_blank">
                    <img src="../../../../assets/images/favicon.png" alt="logo">
                </a>
            </div>
            <div class="side-bar-input">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-control class="header-input" >
                            <i nz-icon nzType="team" class="select-prefix-icon"></i>
                            <nz-select class="header-input" nzPlaceHolder="Search Groups">
                                <nz-option nzLabel="Test" nzValue="Test"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>

            <ul nz-menu nzMode="inline">
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Welcome' : ''"
                    [routerLink]="['/welcome']">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="star" nzTheme="fill"></span>
                    <span>Welcome</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Dashboard' : ''"
                    [routerLink]="['/dashboard']">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="dashboard"></span>
                    <span>Dashboard</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Screens' : ''"
                    [routerLink]="['/display/manage']">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="laptop"></span>
                    <span>Screens</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'My Media' : ''">
                <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="video-camera"></span>
                    <span>My Media</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Media App Store' : ''">
                <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="appstore"></span>
                    <span>Media App Store</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Playlists' : ''">
                <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="file"></span>
                    <span>Playlists</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Broadcasts' : ''">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="wifi"></span>
                    <span>Broadcasts</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Groups' : ''">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="usergroup-add"></span>
                    <span>Groups</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'My Subscriptions' : ''"  [routerLink]="['/subscription/manage']">
                    <div [ngClass]="isCollapsed ? 'active-list-collepse' : 'active-list'"></div>
                    <span nz-icon nzType="carry-out"></span>
                    <span>My Subscriptions</span>
                </li>
            </ul>
        </div>
        <div class="sidebar-bottom-items pl-2">
            <ul nz-menu nzMode="vertical">
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Whats New' : ''">
                    <span nz-icon nzType="dashboard"></span>
                    <span>Whats New</span>
                </li>

                <li nz-submenu nzTitle="Support" nzIcon="setting">
                    <ul>
                        <li nz-menu-item>Test Support
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nz-sider>

    <nz-drawer [nzClosable]="false" [nzVisible]="visible" [nzPlacement]="placement" *ngIf="mobileOne">
        <ng-container *nzDrawerContent class="sidenav-drawer">
            <div class="col-12 text-right">
                <button nz-button nzType="default" nzShape="circle" (click)="close()"><span nz-icon
                        nzType="close"></span></button>
            </div>

            <div class="sidebar-logo">
                <a href="https://ng.ant.design/" target="_blank">
                    <img src="../../../../assets/images/logodark.png" alt="logo">
                </a>
            </div>

            <div class="side-bar-input">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-control class="header-input">
                            <nz-input-group nzPrefixIcon="search">
                                <input nz-input placeholder="Search Grouups" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>

            <ul nz-menu nzMode="inline">
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Welcome' : ''"
                    [routerLink]="['/welcome']" [routerLinkActive]="['active']" >
                    <span nz-icon nzType="star" nzTheme="fill"></span>
                    <span>Welcome</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Dashboard' : ''"
                    [routerLink]="['/dashboard']">
                    <span nz-icon nzType="dashboard"></span>
                    <span>Dashboard</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Screens' : ''"
                    [routerLink]="['/display/manage']">
                    <span nz-icon nzType="laptop"></span>
                    <span>Screens</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'My Media' : ''">
                    <span nz-icon nzType="video-camera"></span>
                    <span>My Media</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Media App Store' : ''">
                    <span nz-icon nzType="appstore"></span>
                    <span>Media App Store</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Playlists' : ''">
                    <span nz-icon nzType="file"></span>
                    <span>Playlists</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Broadcasts' : ''">
                    <span nz-icon nzType="wifi"></span>
                    <span>Broadcasts</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Groups' : ''">
                    <span nz-icon nzType="usergroup-add"></span>
                    <span>Groups</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'My Subscriptions' : ''"  [routerLink]="['/subscription/manage']">
                    <span nz-icon nzType="carry-out"></span>
                    <span>My Subscriptions</span>
                </li>
            </ul>

            <ul nz-menu nzMode="vertical">
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                    [nzTooltipTitle]="isCollapsed ? 'Whats New' : ''">
                    <span nz-icon nzType="dashboard"></span>
                    <span>Whats New</span>
                </li>

                <li nz-submenu nzTitle="Support" nzIcon="setting">
                    <ul>
                        <li nz-menu-item>Test Support
                        </li>
                    </ul>
                </li>
            </ul>
        </ng-container>
    </nz-drawer>
    <nz-layout>
        <nz-header class="header">
            <div class="header-content d-flex justify-content-end align-items-center">
                <div class="search-bar" *ngIf="webOne">
                    <form nz-form>
                        <nz-form-item>
                            <nz-form-control class="header-input">
                                <nz-input-group nzPrefixIcon="search">
                                    <input nz-input placeholder="Search for screen, media, playlist, and broadcast" />
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
                <nz-divider class="mr-3 ml-3" nzType="vertical" *ngIf="webOne"></nz-divider>
                <div *ngIf="webOne">
                    <button nz-button nzType="primary" class="mt-3">
                        <span nz-icon nzType="crown" nzTheme="fill" class="mr-1"></span>Upgrade to pro
                    </button>

                </div>
                <nz-divider class="mr-3 ml-3" nzType="vertical" *ngIf="webOne"></nz-divider>
                <div class="mr-3">
                    <span nz-icon nzType="bell" class="header-icon"></span>
                </div>
                <div>
                    <div nz-dropdown [nzDropdownMenu]="menu">
                        <div class="d-flex align-items-center">
                            <div class="profile-holder fs-16 text-white">{{currentUserInitials}}</div>{{currentUsername}}
                            <span nz-icon nzType="down" class="ml-2"></span>
                        </div>
                    </div>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu [nzMode]="'vertical'">
                            <li nz-menu-item nzSelected>
                                <span nz-icon nzType="user"></span>
                                Profile
                            </li>
                            <li nz-submenu nzTitle="Appearance" nzIcon="alert">
                                <ul>
                                    <li nz-menu-item>Light</li>
                                    <li nz-menu-item>Dark</li>
                                </ul>
                            </li>
                            <li nz-menu-item>
                                <span nz-icon nzType="logout"></span>
                                <span class="pl-1" [routerLink]="['/account/logout']">Sign Out</span>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
                <div class="ml-2" *ngIf="mobileOne">
                    <button nz-button nzType="primary"><span nz-icon nzType="menu" (click)="open()"></span></button>
                </div>
            </div>
        </nz-header>
        <nz-content [ngClass]="isCollapsed ? 'right-layout-m-80' : 'right-layout-m-250'">
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>