import { Component, HostListener } from "@angular/core";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { AppUtils, Constants } from "../../../helpers";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})

export class LayoutComponent{
    placement: NzDrawerPlacement = 'left';
    visible = false;
    isCollapsed = false;
    webOne: boolean = false;
    mobileOne: boolean = false;
    screenHeight: any;
    screenWidth: any;
    currentUsername: string;
    currentUserInitials: string;    

    constructor(private appUtils: AppUtils) {
        this.getScreenSize();
        this.loadCurrentUserProfile();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth <= 1200) {
            this.isCollapsed = true;
        }
        if (this.screenWidth > 1200) {
            this.isCollapsed = false;
        }
        if (this.screenWidth <= 768) {
            this.webOne = false;
            this.mobileOne = true;
        }
        if (this.screenWidth > 768) {
            this.mobileOne = false;
            this.webOne = true;
        }
    }

    loadCurrentUserProfile() {
        const user = JSON.parse(this.appUtils.decrypt(localStorage.getItem(Constants.varUserInfo)));
        this.currentUserInitials = `${user.firstName.charAt(0).toUpperCase()}`;
        this.currentUsername = `${user.firstName}`;
    }

    open(): void {
        this.visible = true;
    }

    close(): void {
        this.visible = false;
    }
}