import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppUtils } from './app.utils';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private appUtils: AppUtils) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedIn = this.appUtils.isUserAuthenticated();

        if (!loggedIn || !this.appUtils.isUserInfoExists()) {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        if (route.url.filter(x => x.path === 'onboarding').length === 0 && !this.appUtils.isOnboardingStepFinished()) {
            this.router.navigate([`/onboarding/${this.appUtils.getCurrentOnboardingUrl()}`],
                { queryParams: this.appUtils.getCurrentOnboardingQueryParams(route) });
            return false;
        }
        return true;
    }
}
